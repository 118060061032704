/******************************************************************************************
PIXEL TO REM CONVERSION TABLE - Root Size: 16px
-------------------------------------------------------------------------------------------
PX   REM     |  PX   REM     |  PX   REM     |  PX   REM     |  PX   REM     |  PX   REM
-------------------------------------------------------------------------------------------
10 = 0.625   |  20 = 1.25    |  30 = 1.875   |  40 = 2.5     |  50 = 3.125   |  60 = 3.75
11 = 0.6875  |  21 = 1.3125  |  31 = 1.9375  |  41 = 2.5625  |  51 = 3.1875  |  61 = 3.8125
12 = 0.75    |  22 = 1.375   |  32 = 2       |  42 = 2.625   |  52 = 3.25    |  62 = 3.875
13 = 0.8125  |  23 = 1.4375  |  33 = 2.0625  |  43 = 2.6875  |  53 = 3.3125  |  63 = 3.9375
14 = 0.875   |  24 = 1.5     |  34 = 2.125   |  44 = 2.75    |  54 = 3.375   |  64 = 4
15 = 0.9375  |  25 = 1.5625  |  35 = 2.1875  |  45 = 2.8125  |  55 = 3.4375
16 = 1       |  26 = 1.625   |  36 = 2.25    |  46 = 2.875   |  56 = 3.5
17 = 1.0625  |  27 = 1.6875  |  37 = 2.3125  |  47 = 2.9375  |  57 = 3.5625
18 = 1.125   |  28 = 1.75    |  38 = 2.375   |  48 = 3       |  58 = 3.625
19 = 1.1875  |  29 = 1.8125  |  39 = 2.4375  |  49 = 3.0625  |  59 = 3.6875
-------------------------------------------------------------------------------------------
******************************************************************************************/


// Basic Colors
$white: #FFFFFF;
$black: #000000;

// Main Brand Color
$brandcolor1-white0: #fbfff2;
$brandcolor1-white1: #f6ffe5;
$brandcolor1-white2: #f2ffd9;
$brandcolor1-white3: #edffcc;
$brandcolor1-light: #c7fc65;
$brandcolor1: #99ca3c;
$brandcolor1-dark: #6c961e;

// Grays
$gray-white0: #fefefe;
$gray-white1: #fafafa;
$gray-white2: #f5f5f5;
$gray-white3: #f3f3f3;

$gray-light1: #f7f7f7;
$gray-light2: #dedede;
$gray-light3: #c4c4c4;

$gray-mid1: #ababab;
$gray-mid2: #919191;
$gray-mid3: #787878;

$gray-dark1: #5e5e5e;
$gray-dark2: #444444;
$gray-dark3: #2b2b2b;


//Main Brand Fonts
$font-body1: 'Nunito Sans', Helvetica, Calibri, 'Segoe UI', sans-serif;
$font-heading1: 'Cormorant Garamond', Didot, Georgia, 'Times New Roman', 'American Typewriter', serif;
$font-heading2: 'Nunito Sans', Helvetica, Calibri, 'Segoe UI', sans-serif;
$font-nav1: 'Nunito Sans', Helvetica, Calibri, 'Segoe UI', sans-serif;
$font-button1: 'Nunito Sans', Helvetica, Calibri, 'Segoe UI', sans-serif;

//Icon Fonts
$font-icons1: 'Font Awesome 5 Free';
$font-icons2: 'IqAutomation';
