/* News Article Teaser Cards */
.news-teaser-card {
	// width: calc(100% - 1.25em);
	margin: 0 0 2.5em 0;
	&:hover {
		.img-zoom-hover {
			img {
				transform: scale(1.125);
			}
		}
		.card-body {
			.news-article-headline-container {
				.news-article-headline {
					color: $brandcolor1-dark;
					text-decoration: none;
				}
			}
		}
		.card-footer {
			.card-news-bottom {
				a {
					color: $black;
					text-decoration: none;
					.fa, .fac {
						color: $black;
					}
				}
			}
		}
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		.card-body {
			.news-article-headline-container {
				.news-article-headline {
					color: $black;
					text-decoration: none;
				}
			}
		}
		.card-footer {
			.card-news-bottom {
				a {
					color: $brandcolor1-dark;
					text-decoration: none;
					.fa, .fac {
						color: $brandcolor1-dark;
					}
				}
			}
		}
	}
	.img-zoom-hover {
		@extend .border-radius-rounded;
	}
	.card-body {
		padding: 1.25em 0 0 0;
		.news-article-date-container {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding-bottom: 0.625rem;
			.news-article-category {
				color: $gray-light2;
				font-size: 0.75rem;
				// font-size: clamp(0.625rem, calc(0.625rem + 0.125 * ((100vw - 36em) / 39)), 0.75rem);
				// font-size: clamp(0.625rem, -webkit-calc(0.625rem + 0.125 * ((100vw - 36em) / 39)), 0.75rem);
				// font-size: clamp(0.625rem, -moz-calc(0.625rem + 0.125 * ((100vw - 36em) / 39)), 0.75rem);
				a {
					color: $gray-mid2;
					font-weight: 600;
					text-transform: uppercase;
				}
			}
			.news-article-date {
				color: $gray-mid2;
				font-size: 0.75rem;
				// font-size: clamp(0.625rem, calc(0.625rem + 0.125 * ((100vw - 36em) / 39)), 0.75rem);
				// font-size: clamp(0.625rem, -webkit-calc(0.625rem + 0.125 * ((100vw - 36em) / 39)), 0.75rem);
				// font-size: clamp(0.625rem, -moz-calc(0.625rem + 0.125 * ((100vw - 36em) / 39)), 0.75rem);
				font-weight: 600;
				// text-transform: uppercase;
				// letter-spacing: 0.1em;
			}
		}
		.news-article-headline-container {
			.news-article-headline {
				font-size: 1.75rem;
				color: $black;
				text-transform: none;
				line-height: 1;
			}
		}
		.news-article-teaser-copy {
			color: $gray-mid2;
			font-size: 0.9375em;
			line-height: 1.25;
		}
	}
	.card-footer {
		padding: 0.3125em 0;
	}
}

/* Main Articles Page */
.news-main-container {
	padding: 2.5em 0 5.0em 0;
	.news-cards-container {
		padding: 0 1.875rem 0 2.5em;
		@media (max-width: 767.9px) {
			padding: 0 2.5rem 0 2.5em;
		}
		.news-card-row {
			.news-card-container {
			}
		}
	}
}

.news-categories-container {
	margin: 0;
	padding: 0 2.5rem 2.5rem 0;
	z-index: 2;
	@media (max-width: 767.9px) {
		padding: 0 2.5rem 1.25rem 2.5rem;
	}
	.categories-sticky-container {
		@media (min-width: 767.9px) {
			position: -webkit-sticky;
			position: -moz-sticky;
			position: -ms-sticky;
			position: -o-sticky;
			position: sticky;
			padding-top: 1.25rem;
		}
	}
	.category-list-title {
		margin: 0 0 0.6125em 0;
		padding: 0 0 0.6125em 0;
		font-size: 0.9375rem;
		font-weight: 600;
		line-height: 1;
		letter-spacing: 0.05rem;
		color: $gray-mid2;
		text-transform: uppercase;
		border-bottom: 1px solid $black;
	}
	ul li {
		position: relative;
		display: block;
		font-family: $font-heading2;
		font-size: 0.75rem;
		font-weight: 600;
		text-transform: uppercase;
		letter-spacing: 0.1em;
		&.active {
			a {
				color: $brandcolor1-dark;
			}
		}
		a {
			display: block;
			color: $black;
			margin-right: 0.625rem;
			padding: 0.125rem 0;
			@media (max-width: 767.9px) {
				padding: 0.625rem 0;
			}
			&::after {
				content: "\f00f";
				font-family: $font-icons2;
				display: inline-block;
				font-size: 0.625em;
				font-weight: normal;
				font-style: normal;
				font-variant: normal;
				text-rendering: auto;
				-webkit-font-smoothing: antialiased;
				text-rendering: auto;
				position: absolute;
				top: 1.5em;
				right: 0;
				color: $brandcolor1-dark;
				@media (max-width: 767.9px) {
					top: 2.25em;
				}
			}
			&:hover {
				text-decoration: none;
				color: $brandcolor1-dark !important;
				&::after {
					color: $black;
				}
			}
			&:active, &:focus, &:not(:disabled):not(.disabled):focus {
				text-decoration: none;
				color: $brandcolor1;
				&::after {
					color: $black;
				}
			}
		}
	}
}

/* News Detail Pages */
.news-body-container {
	padding: 0 4.0em 2.5em 4.0em;
	.page-subtitle-container {
		padding: 0;
		.news-subheadline-text {
			font-size: 1.5em;
			b, strong {
				font-weight: 400;
				color: $gray-dark1;
			}
		}
	}
	.news-container {
		padding: 1.875em 0 0 0;
		.video-thumb-container {
			margin: 0 0 2.5em 0;
			.news-video-featured-thumbnail {
				width: 100%;
				height: auto;
			}
		}
		.news-article-featured-image-container {
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 0 0 2.5em 0;
			.news-article-featured-image {
				// width: 100%;
				// height: auto;
			}
		}
	}
}

/* Latest News Block */
.block-container-latest-news {
	.block-latest-news-header-row {
		margin: 0;
		padding: 0 0 1.25rem 0;
		.block-latest-news-header-container {
			margin: 0;
			padding: 0;
			.block-latest-news-header {
				margin: 0;
				padding: 0;
				color: $white;
				line-height: 1;
				@media (max-width: 575.9px) {
					padding: 0 0 0.625rem 0;
				}
			}
		}
		.block-latest-news-button-container {
			margin: 0;
			padding: 0;
			.btn-block-latest-news-more-news {
			}
		}
	}
	.block-latest-news-card-row {
		.block-latest-news-card-container {
			.news-teaser-card {
		&:hover {
			.card-body {
				.news-article-headline-container {
					.news-article-headline {
						color: $brandcolor1-light;
						text-decoration: none;
					}
				}
			}
			.card-footer {
				.card-news-bottom {
					a {
						color: $white;
						text-decoration: none;
						.fa, .fac {
							color: $white;
						}
					}
				}
			}
		}
		&:active, &:focus, &:not(:disabled):not(.disabled):focus {
			.card-body {
				.news-article-headline-container {
					.news-article-headline {
						color: $white;
					}
				}
			}
			.card-footer {
				.card-news-bottom {
					a {
						color: $brandcolor1-light;
						.fa, .fac {
							color: $brandcolor1-light;
						}
					}
				}
			}
		}
		.img-zoom-hover {
		}
		.card-body {
			.news-article-date-container {
				.news-article-category {
					color: $gray-dark1;
					a {
						color: $gray-mid1;
					}
				}
				.news-article-date {
					color: $gray-mid1;
				}
			}
			.news-article-headline-container {
				.news-article-headline {
					color: $white;
				}
			}
			.news-article-teaser-copy {
				color: $gray-mid1;
			}
		}
		.card-footer {
			font-weight: 700;
		}
	}
		}
	}
}