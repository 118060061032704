.block-banner-outer-container {
	margin: 0;
	padding: 0;
	overflow: hidden;
}

// Swiper JS Classes
.banner-slide {
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	-webkit-transform-style: preserve-3d;
	-moz-transform-style: preserve-3d;
	transform-style: preserve-3d;
	position: relative;
	z-index: 1;
}
.banner-image {
	width: 100%;
	height: auto;
	visibility: hidden;
}


// Banner Overlays
.container-caption {
	display: block;
	position: absolute;
	z-index: 3;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	.container-fluid {
		display: flex;
		justify-content: stretch;
		align-items: center;
		.carousel-caption {
			position: static;
			display: block;
			width: 100%;
			margin: 0;
			padding: 0 2.5em;
			// transform: translateX(10%);
			-webkit-transition: transform 1.0s ease-out;
			-moz-transition: transform 1.0s ease-out;
			-ms-transition: transform 1.0s ease-out;
			-o-transition: transform 1.0s ease-out;
			transition: transform 1.0s ease-out;
			// @media (max-width: 991.9px) {
			// 	width: 90% !important;
			// }
			// @media (max-width: 767.9px) {
			// 	width: 100% !important;
			// }
			// @media (max-width: 575.9px) {
			// 	width: 100% !important;
			// }
			.caption-headline-block {
				position: relative;
				padding-bottom: 0;
				.caption-headline {
					h1, h2, h3, h4, h5, h6 {
						margin: 0 0 0.3125rem 0;
						padding: 0;
						color: $white;
						sup {
							display: inline-block;
							padding-left: 0.25em;
							font-size: 0.25em;
							transform: translateY(-1.625em);
						}
					}
				}
				h4, h5, h6 {
					font-weight: 300;
				}
			}
			.caption-body {
				padding: 0;
				color: $white;
				p {
					line-height: 1;
				}
				.btn-lg  {
					margin-top: 1.25rem;
					padding: 0.9375rem 5.0rem !important;
				}
			}
		}
	}
}
.carousel-overlay-1, .carousel-overlay-2 {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	padding: 0;
	margin: 0;
}
.carousel-overlay-1 {
	opacity: 1.0;
	background: linear-gradient(180deg, rgba($black,0.60) 0%, rgba($black,0.2) 30%, rgba($black,0.2) 50%, rgba($black,0.6) 66%, rgba($black,1.0) 100%);
	z-index: 1;
}
.carousel-overlay-2 {
	// opacity: 0.5;
	// background: radial-gradient(circle, rgba($black,0) 0%, rgba($black,1.0) 100%);
	// z-index: 2;
}


// Banner Down Arrow
#banner-down-arrow {
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	@supports (height:100dvh) {
		height: 100dvh;
	}
	z-index: 1;
	text-align: center;
	pointer-events: none;
}
#banner-down-arrow a {
	position: absolute;
	bottom: 4.0em;
	left: 0;
	width: 100vw;
	-webkit-animation: anim-banner-down-arrow 2s infinite;
	animation: anim-banner-down-arrow 2s infinite;
	.fa, .fac {
		font-size: 3.125em;
		color: $white;
		&:hover {
			color: $brandcolor1-light;
		}
		&:active, &:focus, &:not(:disabled):not(.disabled):focus {
			color: $brandcolor1-dark;
		}
	}
}
@-webkit-keyframes anim-banner-down-arrow {
	0% {
		-webkit-transform: translate(0, 0);
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	100% {
		-webkit-transform: translateY(2.0em);
		opacity: 0;
	}
}
@keyframes anim-banner-down-arrow {
	0% {
		transform: translate(0, 0);
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	100% {
		transform: translateY(2.0em);
		opacity: 0;
	}
}