.link-text-nav, .link-text-subnav {
	color: $gray-dark2;
	font-family: $font-nav1;
	font-weight: 700;
	font-size: 0.75rem;
	font-size: clamp(0.5rem, calc(0rem + 0.75 * ((100vw - 36rem) / 39)), 0.75rem);
	font-size: clamp(0.5rem, -webkit-calc(0rem + 0.75 * ((100vw - 36rem) / 39)), 0.75rem);
	font-size: clamp(0.5rem, -moz-calc(0rem + 0.75 * ((100vw - 36rem) / 39)), 0.75rem);
	line-height: 1;
	text-transform: uppercase;
	letter-spacing: 0.15em;
	text-decoration: none;
	-webkit-transition: color 0.5s ease-out, border-color 0.5s ease-out, background-color 0.5s ease-out;
	-moz-transition: color 0.5s ease-out, border-color 0.5s ease-out, background-color 0.5s ease-out;
	-ms-transition: color 0.5s ease-out, border-color 0.5s ease-out, background-color 0.5s ease-out;
	-o-transition: color 0.5s ease-out, border-color 0.5s ease-out, background-color 0.5s ease-out;
	transition: color 0.5s ease-out, border-color 0.5s ease-out, background-color 0.5s ease-out;
	@include media-breakpoint-up(lg) {
		white-space: nowrap;
	}
	&:hover {
		color: $brandcolor1;
		text-decoration: none;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $black;
		text-decoration: none;
	}
	.fac {
		color: $brandcolor1;
	}
}

.link-text-subnav {
	color: $gray-mid2;
	font-family: $font-nav1;
	font-size: clamp(0.5rem, calc(0rem + 0.75 * ((100vw - 36rem) / 39)), 0.75rem);
	font-size: clamp(0.5rem, -webkit-calc(0rem + 0.75 * ((100vw - 36rem) / 39)), 0.75rem);
	font-size: clamp(0.5rem, -moz-calc(0rem + 0.75 * ((100vw - 36rem) / 39)), 0.75rem);
	font-weight: 700;
	text-decoration: none;
	&.active {
		color: $white;
	}
	.fac {
		font-size: 0.8em;
		transform: translateY(-0.125em);
		color: $gray-mid2;
		&:hover {
			color: $brandcolor1;
		}
		&:active, &:focus, &:not(:disabled):not(.disabled):focus {
			color: $brandcolor1;
		}
	}
}

.link-text-sm {
	color: $brandcolor1;
	font-family: $font-nav1;
	font-weight: 700;
	font-size: 0.625em;
	// font-size: clamp(0.5em, -webkit-calc(0.5em + 0.125 * ((100vw - 36em) / 39)), 0.625rem);
	// font-size: clamp(0.5em, -moz-calc(0.5em + 0.125 * ((100vw - 36em) / 39)), 0.625rem);
	// font-size: clamp(0.5em, calc(0.5em + 0.125 * ((100vw - 36em) / 39)), 0.625rem);
	line-height: 1;
	text-transform: uppercase;
	letter-spacing: 0.15em;
	text-decoration: none;
	-webkit-transition: color 0.5s ease-out;
	-moz-transition: color 0.5s ease-out;
	-ms-transition: color 0.5s ease-out;
	-o-transition: color 0.5s ease-out;
	transition: color 0.5s ease-out;
	&:hover {
		color: $black;
		text-decoration: none;
		.fa, .fac {
			color: $black;
		}
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $black;
		text-decoration: none;
		.fa, .fac {
			color: $black;
		}
	}
	.fa, .fac {
		font-size: 0.8em;
		transform: translateX(0.25em);
	}
}

.nav-tabs {
	position: relative;
	z-index: 50;
}

.nav-sub-inline {
	@media (max-width: 575.9px) {
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
	}
	.list-inline-item {
		margin: 0 0.625rem;
		@media (max-width: 575.9px) {
			display: block;
			margin: 0;
			padding: 1.0rem 0;
			width: 100%;
			text-align: center;
			&:not(:last-child) {
				border-bottom: 1px solid $gray-white1;
			}
		}
	}
}

.btn-nav-accordion-button {
	margin: 0;
	padding: 1.25rem 0 !important;
	border-radius: 0 !important;
	border-bottom: 1px solid $black;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	&:hover {
		border: 1px solid rgba($black, 0) !important;
		border-bottom: 1px solid $black !important;
	}
	.active.focus, .active:focus, .focus, .focus:active, &:active:focus, &:focus {
		outline: 0 !important;
		outline-offset: 0 !important;
		background-image: none !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		border: 1px solid rgba($black, 0) !important;
		border-bottom: 1px solid $black !important;
	}
	&[aria-expanded="true"] {
		.card-title {
			.fa, .fac { transform: rotate(180deg); }
		}
	}
	@media (max-width: 575.9px) {
		margin-bottom: 1.25rem;
	}
	.fa, .fac {
		display: inline-block;
		margin-right: 0.3125em;
		line-height: 1;
		transition: transform 300ms ease-in-out;
		transform: rotate(0deg);
		color: $brandcolor1;
	}
	.category-nav-text {
		display: inline-block;
		margin: 0;
		padding: 0;
		font-size: 0.8125rem;
		color: $black;
		line-height: 1;
		@extend .link-text-subnav;
	}
}

header {
	nav {
		.navbar-collapse {
			background: none;
			.header-logo-menu {
				display: none;
			}
			.navbar-nav {
				margin: 0 1.875rem;
				width: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;
				@media (min-width: 991.9px) {
					li:hover>.dropdown-menu {
						display: block;
					}
				}
				.nav-link, .dropdown-item {
					margin: 0 1.25rem;
					padding: 0.4375em 0;
					margin-left: clamp(0.625rem, -webkit-calc(0.625rem + 0.625 * ((100vw - 36em) / 39)), 1.25rem);
					margin-left: clamp(00.625rem, -moz-calc(0.625rem + 0.625 * ((100vw - 36em) / 39)), 1.25rem);
					margin-left: clamp(00.625rem, calc(0.625rem + 0.625 * ((100vw - 36em) / 39)), 1.25rem);
					margin-right: clamp(0.625rem, -webkit-calc(0.625rem + 0.625 * ((100vw - 36em) / 39)), 1.25rem);
					margin-right: clamp(0.625rem, -moz-calc(0.625rem + 0.625 * ((100vw - 36em) / 39)), 1.25rem);
					margin-right: clamp(0.625rem, calc(0.625rem + 0.625 * ((100vw - 36em) / 39)), 1.25rem);
				  color: $white;
				  border-bottom: 4px solid rgba($brandcolor1,0);
					&:hover {
						color: $brandcolor1-light;
					}
					&:active, &:focus, &:not(:disabled):not(.disabled):focus {
						color: $brandcolor1;
					}
				  &.active:not(.btn-navlink) {
						border-bottom: 4px solid $brandcolor1;
					}
				}
				.btn-navlink {
					padding: 0.75rem 2.0rem;
					border: 1px solid $brandcolor1-light;
					border-radius: 6.0rem;
					background-color: none;
					color: $brandcolor1-light;
					border-color: $brandcolor1-light;
					border-bottom-width: 1px;
					margin-bottom: 4px;
					&:hover {
						color: $white;
						background-color: $brandcolor1;
						border-color: $brandcolor1;
					}
					&:active, &:focus, &:not(:disabled):not(.disabled):focus {
						color: $white;
						background-color: $brandcolor1-dark;
						border-color: $brandcolor1-dark;
					}
					&.active {
						@extend .btn-primary;
						//color: $white !important;
					}
				}
				.nav-item {
					margin: 0;
					position: relative;
					.nav-link {
						position: relative;
					}
					&.dropdown {
						&:hover {
							.dropdown-toggle {
								color: $brandcolor1-light
								&::after {
									color: $white;
								}
							}
							.dropdown-menu {
								visibility: visible;
								transform: translateY(0);
								transform: translateX(0.625rem);
								opacity: 1;
								.dropdown-item {
									opacity: 1;
								}
							}
						}
						.dropdown-toggle {
							&::after {
								content: "\f01c";
								font-family: $font-icons2;
								display: inline-block;
								font-size: 1.0em;
								font-weight: normal;
								font-style: normal;
								line-height: 1;
								text-rendering: auto;
								border: none;
								transform: translateY(0.125em);
								padding-left: 0.125em;
								color:$brandcolor1-light;
							}
							@include media-breakpoint-up(lg) {
								&::after {
									display: inline-block;
								}
							}
						}
						.dropdown-menu {
							display: block;
							visibility: hidden;
							margin: 0 0 0 -0.625rem;
							padding: 0.625rem 0;
							border-radius: 0;
							border: none;
							background-color: rgba($gray-dark2, 1.0);
							opacity: 0;
							transform: translateY(-1.25rem);
							transform: translateX(0.625rem);
							-webkit-transition: opacity 0.5s ease-out, transform 0.5s ease-out, height 0.5s ease-out;
							-moz-transition: opacity 0.5s ease-out, transform 0.5s ease-out, height 0.5s ease-out;
							-ms-transition: opacity 0.5s ease-out, transform 0.5s ease-out, height 0.5s ease-out;
							-o-transition: opacity 0.5s ease-out, transform 0.5s ease-out, height 0.5s ease-out;
							transition: opacity 0.5s ease-out, transform 0.5s ease-out, height 0.5s ease-out;
							.dropdown-item {
								margin: 0;
								padding: 0.6125em 1.25rem;
								width: auto;
								opacity: 0;
								// border-bottom: 1px solid rgba($brandcolor1, 1.0);
								// &:last-child { 
								// 	border-bottom: none;
								// }
								&.active {
									background: none;
									color: $brandcolor1-light;
									border-bottom: none;
								}
								&:hover {
									background: none;
									color: $brandcolor1-light;
								}
								&:active, &:focus, &:not(:disabled):not(.disabled):focus {
									background: none;
									color: $brandcolor1;
								}
							}
							.nav-item {
								> li:hover {
									> .submenu {
										display: block;
									}
								}
							}
						}
						.submenu {
							@include media-breakpoint-up(lg) {
								display: none;
								position: absolute;
								top: 0;
								left: 100%;
								right: auto;
								&.submenu-right {
									right: 100%;
									left: auto;
								}
							}
						}
					}
				}
			}
		}
	}
	.navbar-toggler {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		margin: 1.25rem 0;
		padding: 0 2.5rem !important;
		// padding-left: clamp(0.625em, calc(0.625em + 1.875 * ((100vw - 62em) / 13)), 2.5em) !important;
		// padding-right: clamp(0.625em, calc(0.625em + 1.875 * ((100vw - 62em) / 13)), 2.5em) !important;
		border: none;
		color: $white;
		&:hover:not(.collapsed) {
			.navbar-toggler-icon {
				margin: 0;
				background-image: none !important;
				&>span {
					margin: 6px 0;
				}
			}
		}
		&:not(.collapsed) {
			outline: 0 !important;
			outline-offset: 0  !important;
			background-image: none !important;
			-webkit-box-shadow: none !important;
			-moz-box-shadow: none !important;
			box-shadow: none !important;
			.navbar-toggler-icon {
				margin: 0;
				background-image: none !important;
				&>span {
					margin: 6px 0;
					background-color: $white !important;
					&:first-child {
						-webkit-transform: translateY(9px) rotate(45deg);
						-moz-transform: translateY(9px) rotate(45deg);
						-ms-transform: translateY(9px) rotate(45deg);
						-o-transform: translateY(9px) rotate(45deg);
						transform: translateY(9px) rotate(45deg);
					}
					&:nth-child(2) {
						opacity: 0;
					}
					&:last-child {
						-webkit-transform: translateY(-9px) rotate(-45deg);
						-moz-transform: translateY(-9px) rotate(-45deg);
						-ms-transform: translateY(-9px) rotate(-45deg);
						-o-transform: translateY(-9px) rotate(-45deg);
						transform: translateY(-9px) rotate(-45deg);
					}
				}
			}
		}
		.active.focus, .active:focus, .focus, .focus:active, &:active:focus, &:focus {
			outline: 0 !important;
			outline-offset: 0  !important;
			background-image: none !important;
			-webkit-box-shadow: none !important;
			-moz-box-shadow: none !important;
			box-shadow: none !important;
		}
		.navbar-toggler-icon {
			margin: 4px 0;
			background-image: none !important;
			-webkit-transition: margin 0.25s;
			-moz-transition: margin 0.25s;
			-ms-transition: margin 0.25s;
			-o-transition: margin 0.25s;
			transition: margin 0.25s;

			&>span {
				display: block;
				content: '';
				margin: 4px 0;
				width: 100%;
				height: 3px;
				background: $white;
				border-radius: 1.5px;
				-webkit-transition: transform 0.25s, margin 0.25s, opacity 0.25s, background 0.25s;
				-moz-transition: transform 0.25s, margin 0.25s, opacity 0.25s, background 0.25s;
				-ms-transition: transform 0.25s, margin 0.25s, opacity 0.25s, background 0.25s;
				-o-transition: transform 0.25s, margin 0.25s, opacity 0.25s, background 0.25s;
				transition: transform 0.25s, margin 0.25s, opacity 0.25s, background 0.25s;
			}
			// background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(33,33,33,1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
		}
	}
}

@media (max-width: 991.9px) {
	header {
		position: relative;
		margin: 0 !important;
		overflow-y: none;
		nav.navbar {
			.navbar-collapse {
				position: fixed;
				top: -1.25rem;
				left: 0;
				right: 0;
				width: 100vw !important;
				margin: 0 !important;
				padding: 0;
				background-color: $black;
				position: relative;
				z-index: 1;
				width: 100%;
				text-align: center;
				&.show {
					margin: 0;
					padding: 0;
					min-height: calc(100vh + 1.25rem);
					@supports (height:100dvh) {
						min-height: calc(100dvh + 1.25rem);
					}
					max-height: -moz-available;
					max-height: -webkit-fill-available;
					max-height: fill-available;
					overflow-y: scroll;
				}
				.navbar-nav {
					margin: 0;
					padding: 0;
					min-height: calc(100vh + 1.25rem);
					@supports (height:100dvh) {
						min-height: calc(100dvh + 1.25rem);
					}
					max-height: -moz-available;
					max-height: -webkit-fill-available;
					max-height: fill-available;
					overflow-y: scroll;
					border: none;
					float: none;
					z-index: 99;
					display: flex;
					justify-content: center;
					align-items: center;
					.nav-link, .dropdown-item {
						padding: 2vh 2vw !important;
						font-size: 1.875rem;
						font-size: clamp(1.25rem, calc(1.25rem + 0.625 * ((100vw - 36em) / 39)), 1.875rem);
						font-size: clamp(1.25rem, -webkit-calc(1.25rem + 0.625 * ((100vw - 36em) / 39)), 1.875rem);
						font-size: clamp(1.25rem, -moz-calc(1.25rem + 0.625 * ((100vw - 36em) / 39)), 1.875rem);
						letter-spacing: 0.3em;
						line-height: 1;
						text-decoration: none;
						&.btn-navlink {
							margin-top: 2vh;
							padding: 2vh 4vw !important;
							font-size: 1.5rem;
							font-size: clamp(1.0rem, calc(1.0rem + 0.5 * ((100vw - 36em) / 39)), 1.5rem);
							font-size: clamp(1.0rem, -webkit-calc(1.0rem + 0.5 * ((100vw - 36em) / 39)), 1.5rem);
							font-size: clamp(1.0rem, -moz-calc(1.0rem + 0.5 * ((100vw - 36em) / 39)), 1.5rem);
						}
						&:hover {
						}
						&:active, &:focus, &:not(:disabled):not(.disabled):focus {
						}
						&.active {
							color: $brandcolor1;
							border-bottom: none;
						}
					}
					.dropdown-item {
						&:hover {
							background: none;
						}
						&:active, &:focus, &:not(:disabled):not(.disabled):focus {
						}
					}
					.nav-item {
						.nav-link {
						}
						&.dropdown {
							.dropdown-toggle {
							}
							.dropdown-menu {
								background: none;
								&.collapse {
									display: none;
								}
								&.show {
									display: block;
								}
								&.submenu {
								}
								.dropdown-item {
									text-align: center;
								}
							}
						}
					}
				}
			}
		}
		.navbar-toggler {
			position: absolute;
			top: -0.625rem;
			right: 0;
			z-index: 1020;
		}	
	}
}
