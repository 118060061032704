.btn {
	margin: 0.25rem 0.125rem;
	padding: 0.75rem 2.0rem;
	background: none;
	border-width: 1px;
	border-radius: 2.0rem;
	font-family: $font-button1;
	font-size: 0.75rem;
	// font-size: clamp(0.5rem, calc(0.5rem + 0.25 * ((100vw - 36rem) / 39)), 0.75rem);
	// font-size: clamp(0.5rem, -webkit-calc(0.5rem + 0.25 * ((100vw - 36rem) / 39)), 0.75rem);
	// font-size: clamp(0.5rem, -moz-calc(0.5rem + 0.25 * ((100vw - 36rem) / 39)), 0.75rem);
	font-weight: 600;
	text-transform: uppercase;
	letter-spacing: 0.15rem;
	line-height: 1;
	text-decoration: none !important;
	-webkit-transition: color 1.0s ease-out, background 1.0s ease-out, border 1.0s ease-out;
	-moz-transition: color 1.0s ease-out, background 1.0s ease-out, border 1.0s ease-out;
	-ms-transition: color 1.0s ease-out, background 1.0s ease-out, border 1.0s ease-out;
	-o-transition: color 1.0s ease-out, background 1.0s ease-out, border 1.0s ease-out;
	transition: color 1.0s ease-out, background 1.0s ease-out, border 1.0s ease-out;
	&:hover {
		color: $brandcolor1-light;
		background: none;
		border-color: $brandcolor1-light;
		text-decoration: none;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $brandcolor1;
		background: none;
		border-color: $brandcolor1;
		text-decoration: none;
	}
	.active.focus, .active:focus, .focus, .focus:active, &:active:focus, &:focus {
		outline: 0 !important;
		outline-offset: 0  !important;
		background-image: none !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	&.btn-primary, &.btn-secondary, &.btn-dark, &.btn-gray, &.btn-light {
		&:not(.btn-sm) {
			&::after {
				content: '\f018';
				font-family: $font-icons2;
				display: inline-block;
				font-size: inherit;
				font-weight: normal;
				font-style: normal;
				line-height: 1;
				text-rendering: auto;
				margin: 0 0 0 0.625rem;
			}
		}
	}
	&.btn-link::after {
		content: '\f014';
		font-family: $font-icons2;
		display: inline-block;
		font-size: inherit;
		font-weight: normal;
		font-style: normal;
		line-height: 1;
		text-rendering: auto;
		margin: 0 0 0 0.625rem;
	}
}

.btn-sm {
	font-size: 0.625rem;
	// font-size: clamp(0.5rem, -webkit-calc(0.5rem + 0.125 * ((100vw - 36em) / 39)), 0.625rem);
	// font-size: clamp(0.5rem, -moz-calc(0.5rem + 0.125 * ((100vw - 36em) / 39)), 0.625rem);
	// font-size: clamp(0.5rem, calc(0.5rem + 0.125 * ((100vw - 36em) / 39)), 0.625rem);
	padding: 0.5rem 1.0rem;
}

.btn-lg {
	font-size: 0.875rem;
	// font-size: clamp(0.75rem, calc(0.75rem + 0.125 * ((100vw - 36em) / 39)), 0.875rem);
	// font-size: clamp(0.75rem, calc(0.75rem + 0.125 * ((100vw - 36em) / 39)), 0.875rem);
	// font-size: clamp(0.75rem, calc(0.75rem + 0.125 * ((100vw - 36em) / 39)), 0.875rem);
	padding: 0.9375rem 2.5rem;
}

.btn-primary:not(.btn-secondary):not(.btn-gray):not(.btn-dark):not(.btn-light):not(.btn-outline-primary):not(.btn-outline-secondary):not(.btn-outline-gray):not(.btn-outline-dark):not(.btn-outline-light):not(.btn-link) {
	color: $gray-dark2;
	background-color: $brandcolor1;
	border-color: $brandcolor1;
	&:hover {
		color: $gray-dark2;
		background-color: $brandcolor1-light;
		border-color: $brandcolor1-light;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $white;
		background-color: $brandcolor1-dark;
		border-color: $brandcolor1-dark;
	}
}

.btn-secondary {
	color: $gray-dark2;
	background-color: $brandcolor1-light;
	border-color: $brandcolor1-light;
	&:hover {
		color: $gray-dark2;
		background-color: $brandcolor1;
		border-color: $brandcolor1;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $gray-dark2;
		background-color: $brandcolor1-dark;
		border-color: $brandcolor1-dark;
	}
}

.btn-gray {
	color: $white;
	background-color: $gray-mid2;
	border-color: $gray-mid2;
	&:hover {
		color: $brandcolor1-light;
		background: $gray-mid3;
		border-color: $gray-mid3;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $brandcolor1;
		background: $gray-dark1;
		border-color: $gray-dark1;
	}
}

.btn-dark {
	color: $white;
	background-color: $black;
	border-color: $black;
	&:hover {
		color: $brandcolor1-light;
		background-color: $gray-dark2;
		border-color: $gray-dark2;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $white;
		background-color: $gray-dark3;
		border-color: $gray-dark3;
	}
}

.btn-light {
	color: $brandcolor1;
	background-color: $white;
	border-color: $white;
	&:hover {
		color: $brandcolor1-dark;
		background-color: $white;
		border-color: $white;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $black;
		background-color: $white;
		border-color: $white;
	}
}

.btn-outline-primary {
	background-color: none;
	color: $brandcolor1;
	border-color: $brandcolor1;
	&:hover {
		color: $white;
		background-color: $brandcolor1;
		border-color: $brandcolor1;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $white;
		background-color: $brandcolor1-dark;
		border-color: $brandcolor1-dark;
	}
}

.btn-outline-secondary {
	background-color: none;
	color: $brandcolor1-light;
	border-color: $brandcolor1-light;
	&:hover {
		color: $white;
		background-color: $brandcolor1;
		border-color: $brandcolor1;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $white;
		background-color: $brandcolor1-dark;
		border-color: $brandcolor1-dark;
	}
}

.btn-outline-gray {
	color: $gray-mid2;
	border-color: $gray-mid2;
	&:hover {
		color: $white;
		background-color: $gray-light3;
		border-color: $gray-light3;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $white;
		background-color: $gray-dark1;
		border-color: $gray-dark1;
	}
}

.btn-outline-dark {
	background-color: none;
	color: $black;
	border-color: $black;
	&:hover {
		color: $white;
		background-color: $brandcolor1;
		border-color: $brandcolor1;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $white;
		background-color: $brandcolor1-dark;
		border-color: $brandcolor1-dark;
	}
}

.btn-outline-light {
	background-color: none;
	color: $white;
	border-color: $white;
	&:hover {
		color: $white;
		background-color: $brandcolor1;
		border-color: $brandcolor1;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $white;
		background-color: $brandcolor1-dark;
		border-color: $brandcolor1-dark;
	}
}

.btn-link {
	margin: 0;
	padding: 0;
	color: $brandcolor1;
	text-decoration: none;
	border: none;
	border-color: rgba($black, 0);
	&:hover {
		color: $brandcolor1-light;
		text-decoration: underline;
		border: none;
		border-color: rgba($black, 0);
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $brandcolor1-dark;
		text-decoration: underline;
		border: none;
		border-color: rgba($black, 0);
	}
	&.text-light {
		color: $brandcolor1-light;
		&:hover {
			color: $brandcolor1;
		}
	}
}
