footer {
	@media (max-width: 575.9px) {
		font-size: 1.0625rem;
	}
}

//Footer Top Rows
.footer-top {
	background-color: $gray-dark2;
	margin: 0;
	padding: 0;
	.footer-top-inner {
		padding: 2.5em 2.5em;
		.footer-info-container {
			display: flex;
			justify-content: flex-start;
			align-items: flex-start;
			@media (max-width: 991.9px) {
				justify-content: center;
			}
			.footer-info-inner-container {
				color: $white;
				font-size: 1.0em;
				line-height: 1;
				text-align: left;
				@media (max-width: 991.9px) {
					font-size: 1.0em;
					margin-bottom: 2.5rem;
					text-align: center;
				}
				@media (max-width: 575.9px) {
					font-size: 1.125em;
				}
				b, strong {
					font-weight: 700;
					color: $white;
				}
				a {
					color: $white;
					&:hover {
						color: $brandcolor1-light;
						text-decoration: none;
					}
					&:active, &:focus, &:not(:disabled):not(.disabled):focus {
						color: $white;
						text-decoration: none;
					}
				}
				.footer-logo-container {
					display: flex;
					justify-content: center;
					align-items: center;
					margin: 0;
					padding: 0;
					.footer-logo {
						display: inline-block;
						width: 280px;
						height: 57px;
						background: center center/contain no-repeat;
						text-indent: -9999px;
						margin: 0;
						padding: 0;
						// @media (max-width: 1199.9px) {
						// 	width: 240px;
						// 	height: 49px;
						// }
						// @media (max-width: 991.9px) {
						// 	width: 210px;
						// 	height: 43px;
						// }
						// @media (max-width: 767.9px) {
						// 	width: 180px;
						// 	height: 37px;
						// 	margin: 0.9375em 0em;
						// }
						@media (max-width: 575.9px) {
							// width: 120vw;
							// height: 25px;
						}
					}
				}
				.info-phone {
					margin-top: 1.25rem;
					white-space: nowrap;
					a {
						font-weight: 700;
						white-space: nowrap;
					}
				}
				.info-address {
					margin-top: 1.25rem;
					white-space: nowrap;
				}
				.info-email {
					margin-top: 1.25rem;
					white-space: nowrap;
					a {
						font-weight: 700;
						white-space: nowrap;
					}
				}
			}
		}
		.footer-text-container {
			@media (max-width: 767.9px) {
				margin-top: 5.0rem;
			}
			.footer-headline {
				color: $white;
			}
			.footer-subheadline {
				color: $gray-light2;
			}
			.footer-bodycopy {
				color: $gray-light2;
			}
			.footer-buttons {
				margin-top: 0.625rem;
			}
		}
	}
}

//Footer Middle Rows
.footer-middle {
	background-color: $gray-dark2;
	margin: 0;
	padding: 0;
	.footer-middle-inner {
		padding: 2.5em 2.5em;
		.footer-affiliations-container {
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex-wrap: wrap;
			gap: 2.5rem;
			@media (max-width: 767.9px) {
				margin-top: 5.0rem;
				justify-content: center;
				gap: 3.75rem;
			}
		.footer-affiliation {
			@media (max-width: 767.9px) {
				justify-content: center;
			}
			.footer-affiliation-logo {
				max-height: 6.0rem;
				max-width: 16.0rem;
			}
		}
	}
	}
}

// Footer Bottom Rows
.footer-bottom {
	background-color: $gray-dark2;
	margin: 0;
	padding: 0;
	.footer-bottom-inner {
		padding: 1.25rem 2.5rem;
		text-align: center;
		.footer-copyright {
			color: $white;
			font-size: 0.75em;
			line-height: 1;
			@media (max-width: 991.9px) {
				padding: 0.625em 2.5em;
			}
		}
		.footer-links {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			flex-wrap: wrap;
			color: $white;
			@media (max-width: 991.9px) {
				justify-content: center;
				margin-top: 1.25rem
			}
			ul {
				margin: 0;
				padding: 0;
				li.list-inline-item {
					margin: 0;
					padding: 0 0.3125em;
					@media (max-width: 575.9px) {
						padding: 0.3125em 1.25em;
					}
					&:first-child {
						border-right: 1px solid $gray-mid2;
						@media (min-width: 575.9px) {
							padding-left: 0;
						}
					}
					&:last-child {
						@media (min-width: 575.9px) {
							margin-right: 0.625em;
							border-right: 1px solid $gray-mid2;
						}
					}
					a {
						font-family: $font-nav1;
						font-size: 0.625em;
						line-height: 1;
						color: $white;
						padding: 0.3125em;
						&:hover {
							color: $brandcolor1-light;
							text-decoration: none;
						}
						&:active, &:focus, &:not(:disabled):not(.disabled):focus {
							color: $brandcolor1-light;
							text-decoration: none;
						}
					}
				}
			}
		}
		.footer-social-icons {
				margin: 0;
				padding: 0;
				display: flex;
				justify-content: flex-end;
				align-items: center;
				gap: 1.25rem;
				@media (max-width: 991.9px) {
					padding: 0.3125rem 1.25em;
					justify-content: center;
					gap: 2.0rem;
				}
				.footer-social-icon {
					margin: 0;
					padding: 0;
					display: flex;
					justify-content: center;
					align-items: center;
					color: $gray-light3;
					font-size: 2.25em;
					line-height: 1;
					@media (max-width: 991.9px) {
						padding: 0.625rem 2.0rem;
					}
					&:hover {
						color: $brandcolor1-light;
						text-decoration: none;
					}
					&:active, &:focus, &:not(:disabled):not(.disabled):focus {
						color: $white;
						text-decoration: none;
					}
				}
				.footer-social-desc {
					display: none;
				}
			}
	}
}
