header {
	display: block;
	position: absolute;
	z-index: 4;
	top: 0;
	left: 0;
	width: 100%;
	height: auto;
	.header-top-container {
		position: relative;
		background: none;
		@media (min-width: 991.9px) {
			.row {
				display: flex;
				justify-content: center;
				align-items: center;
				@media (max-width: 991.9px) {
					align-items: flex-start;
				}
			}
		}
		.header-logo-container {
			padding: 1.25rem 2.5rem !important;
			padding-left: clamp(0.625em, calc(0.625em + 1.875 * ((100vw - 62em) / 13)), 2.5em);
			padding-right: clamp(0.625em, calc(0.625em + 1.875 * ((100vw - 62em) / 13)), 2.5em);
			.header-logo {
				display: inline-block;
				width: 280px;
				height: 57px;
				background: center center/contain no-repeat;
				text-indent: -9999px;
				margin: 0;
				padding: 0;
				@media (max-width: 1199.9px) {
					width: 240px;
					height: 49px;
				}
				@media (max-width: 991.9px) {
					width: 210px;
					height: 43px;
				}
				@media (max-width: 767.9px) {
					width: 180px;
					height: 37px;
					margin: 0.9375em 0em;
				}
				@media (max-width: 575.9px) {
					// width: 120vw;
					// height: 25px;
				}
			}
		}
		.header-info-container {
			display: inline-block;
			width: auto;
			.row {
				.col-6 {
					width: auto;
				}
			}
			b {
				font-family: $font-nav1;
				font-size: 1.125em;
				text-transform: uppercase;
				color: $black;
				white-space: nowrap;
			}
			a {
				color: $gray-dark1;
				&:hover {
					color: $brandcolor1;
					text-decoration: none;
				}
				&:active, &:focus, &:not(:disabled):not(.disabled):focus {
					color: $black;
					text-decoration: none;
				}
			}
		}
	}
	.header-nav-container {
		margin: 0;
		padding: 1.25rem 0 !important;
		background: none;
		.navbar {
			padding: 0;
		}
	}
}
