// Main Locations Map
.main-map-container {
	position: relative;
	background-color: rgba($gray-dark3, 0.9);
	padding-top: 180px;
	&::before {
		position: absolute;
		background: inherit;
		content: '';
		display: block;
		height: 10.0em;
		top: 0 !important;
		left: 0;
		right: 0;
		z-index: 2;
		-webkit-backface-visibility: hidden; // for Chrome Windows
		transform: skewY(2deg) translateY(-38vw);
		transform-origin: 100% 0;
	}
	.location-body {
		margin-top: 0;
		margin-bottom: 0;
		p {
			margin-top: 0;
			margin-bottom: 0;
		}
	}
	.location-address {
		margin-top: 0;
		margin-bottom: 0;
	}
}

// Main Locations List
.locations-list-container {
	padding-bottom: 7.0em;
	.locations-list-header-container {
		padding: 1.25em 2.5em;
		.locations-list-header {
			color: $brandcolor1;
			font-weight: 600;
		}
	}
	.locations-list-location-container {
		padding: 1.25em 2.5em;
		.location-card {
			&:hover {
				.location-card-header {
					.location-list-title {
						color: $brandcolor1;
					}
				}
			}
			.location-card-header {
				margin: 0;
				padding: 0 0 0.25em 0;
				.location-list-county {
					margin-bottom: 0.0625em;
				}
				.location-list-title {
					color: $gray-mid2;
					padding-bottom: 0.125em;
					margin-bottom: 0;
					border-bottom: 1px solid $gray-light2;
				}
			}
			.location-card-body {
				margin: 0;
				padding: 0;
				line-height: 1;
				p {
					font-size: 0.9375em;
					line-height: 1;
				}
			}
		}
	}
}

// Locations Detail Page
.location-detail-container {
	padding-bottom: 7.0em;
	.location-detail-header-container {
		padding: 0 2.5em 1.25em 2.5em;
	}
	.location-detail-text-container {
		padding: 0 2.5em 1.25em 2.5em;
		.location-detail-phone {
			margin-bottom: 0;
			a {
				color: $black;
				text-decoration: none;
			}
		}
		.location-body {
			margin-top: 0;
			p {
				margin-top: 0;
			}
		}
		.location-detail-address {
			line-height: 1;
			margin-top: 0.9375em;
		}
		.location-detail-directions {
			a {
				text-decoration: none;
				&:hover {
					text-decoration: none;
				}
				&:active, &:focus, &:not(:disabled):not(.disabled):focus {
					text-decoration: none;
				}
			}
		}
	}
	.location-detail-footer {
		margin-top: 1.25em;
		padding-top: 0.625em;
		border-top: 1px solid $gray-light2;
		a {
			color: $gray-dark2;
			text-decoration: none;
			&:hover {
				text-decoration: none;
			}
			&:active, &:focus, &:not(:disabled):not(.disabled):focus {
				text-decoration: none;
			}
		}
	}
}