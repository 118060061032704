.img-zoom-hover {
	position: relative;
	overflow: hidden;
	& > * {
		z-index: 2;
	}
	img {
		transition: all 2.0s ease-out;
	}
}

figure {
	position: relative;
	margin: 0;
	padding: 0;
	figcaption {
		display: block;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		width: 100%;
		margin: 0;
		padding: 0.625rem;
		display: flex;
		justify-content: center;
		align-items: flex-end;
		// text-align: center;
		background: rgba($black, 0.66);
		background: -moz-linear-gradient(0deg, rgba($black,0.66) 0%, rgba($black,0.33) 50%, rgba($black,0) 100%);
		background: -webkit-linear-gradient(0deg, rgba($black,0.66) 0%, rgba($black,0.33) 50%, rgba($black,0) 100%);
		background: linear-gradient(0deg, rgba($black,0.66) 0%, rgba($black,0.33) 50%, rgba($black,0) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
		color: $white;
		font-weight: 100;
		line-height: 1;
		white-space: normal;
		font-size: clamp(0.75rem, calc(0.75rem + 0.25 * ((100vw - 36rem) / 39)), 1.0rem);
		font-size: clamp(0.75rem, -webkit-calc(0.75em + 0.25 * ((100vw - 36em) / 39)), 1.0rem);
		font-size: clamp(0.75rem, -moz-calc(0.75em + 0.25 * ((100vw - 36em) / 39)), 1.0rem);
		a {
			color: $white;
			text-decoration: none;
			white-space: normal;
			&:hover {
				color: $white;
				text-decoration: underline;
			}
			&:active, &:focus, &:not(:disabled):not(.disabled):focus {
				color: $gray-light1;
				text-decoration: underline;
			}
		}
		b, strong, i, em {
			color: $white;
		}
		b, strong {
			font-weight: 400;
		}
		&.caption-and-link {
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex-wrap: wrap;
		}
		.caption-text, &.caption-text {
			color: $white;
			font-weight: 100;
			line-height: 1;
			white-space: normal;
			font-size: clamp(0.75rem, calc(0.75rem + 0.25 * ((100vw - 36rem) / 39)), 1.0rem);
			font-size: clamp(0.75rem, -webkit-calc(0.75em + 0.25 * ((100vw - 36em) / 39)), 1.0rem);
			font-size: clamp(0.75rem, -moz-calc(0.75em + 0.25 * ((100vw - 36em) / 39)), 1.0rem);
			a {
				color: $white;
				text-decoration: none;
				white-space: normal;
				&:hover {
					color: $white;
					text-decoration: underline;
				}
				&:active, &:focus, &:not(:disabled):not(.disabled):focus {
					color: $gray-light1;
					text-decoration: underline;
				}
			}
			b, strong, i, em {
				color: $white;
			}
			b, strong {
				font-weight: 400;
			}
		}
		.caption-text {
			padding: 0 0.625rem;
		}
		&.caption-text {
			padding: 0.625rem;
		}
		.caption-link, &.caption-link {
			color: $white;
			font-size: clamp(0.5rem, calc(0.5rem + 0.25 * ((100vw - 36rem) / 39)), 0.75rem);
			font-size: clamp(0.5rem, -webkit-calc(0.5em + 0.25 * ((100vw - 36em) / 39)), 0.75rem);
			font-size: clamp(0.5rem, -moz-calc(0.5em + 0.25 * ((100vw - 36em) / 39)), 0.75rem);
			font-weight: 600;
			line-height: 1;
			text-transform: uppercase;
			letter-spacing: 0.1em;
			a {
				color: $white;
				text-decoration: none;
				&::after {
					content: '\f008';
					font-family: $font-icons2;
					display: inline-block;
					font-size: clamp(0.75rem, calc(0.75rem + 0.25 * ((100vw - 36rem) / 39)), 1.0rem);
					font-size: clamp(0.75rem, -webkit-calc(0.75em + 0.25 * ((100vw - 36em) / 39)), 1.0rem);
					font-size: clamp(0.75rem, -moz-calc(0.75em + 0.25 * ((100vw - 36em) / 39)), 1.0rem);
					font-weight: normal;
					font-style: normal;
					line-height: 1;
					text-rendering: auto;
					margin: 0 0 0 0.3125rem;
					transform: translateY(0.125rem);
				}
				&:hover {
					color: $white;
					text-decoration: none;
					&::after {
						color: $brandcolor1-light;
					}
				}
				&:active, &:focus, &:not(:disabled):not(.disabled):focus {
					color: $gray-light1;
					text-decoration: none;
					&::after {
						color: $brandcolor1-light;
					}
				}
			}
		}
		.caption-link {
			padding: 0 0.625rem;
		}
		&.caption-link {
			padding: 0.625rem;
			display: flex;
			justify-content: flex-end;
			align-items: center;
		}
	}
}