.entry-pages-home {
	#more-info-form {
		.block-inner-container {
			margin-bottom: calc(-20vh + 120px);
			transform: translateY(-20vh);
			display: block;
			position: relative;
			z-index: 1;
		}
	}
	#about {
		h4  {
			font-size: clamp(1.5rem, -webkit-calc(1.5rem + 0.75 * ((100vw - 36em) / 39)), 2.25em);
			font-size: clamp(1.5rem, -moz-calc(1.5rem + 0.75 * ((100vw - 36em) / 39)), 2.25em);
			font-size: clamp(1.5rem, calc(1.5rem + 0.75 * ((100vw - 36em) / 39)), 2.25em);
			font-weight: 300;
		}
	}	
	#our-brands {
		.block-image-gallery-thumb-container {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 1.25rem 5.0rem;
			@media (max-width: 767.9px) {
				padding: 1.25rem 2.5rem;
			}
			@media (max-width: 575.9px) {
				padding: 1.25rem 0;
			}
			.block-image-gallery-thumb {
				height: auto;
				opacity: 0.5;
				filter: saturate(0%);
				-webkit-transition: filter 1.0s ease-out, opacity 1.0s ease-out;
				-moz-transition: filter 1.0s ease-out, opacity 1.0s ease-out;
				-ms-transition: filter 1.0s ease-out, opacity 1.0s ease-out;
				-o-transition: ffilter 1.0s ease-out, opacity 1.0s ease-out;
				transition: filter 1.0s ease-out, opacity 1.0s ease-out;
				&:hover {
					opacity: 1;
					filter: saturate(100%);
				}
				@media (max-width: 767.9px) {
					transform: scale(.75);
				}
				@media (max-width: 575.9px) {
					transform: scale(.5);
				}
			}
		}
		.block-button-group-container {
			.btn::after {
				content: "";
				position: absolute;
				inset: 0;
				cursor: pointer!important;
				display: block;
				z-index: 99;
			}
		}
	}
}

#more-info-form {
	.block-inner-container {
		.block-headline-text {
			font-family: $font-heading2;
			font-weight: 100;
		}
		input {
			background: none !important;
			border: 1px solid $gray-dark2 !important;
		}
		label {
			color: $gray-mid1 !important;
			font-size: 0.875rem;
		}
		.btn {
			padding: 0.9375rem 5.0rem !important;
		}
		p {
			a {
				color: $brandcolor1 !important;
				text-decoration: none;
				&:hover {
					color: $brandcolor1-light;
					text-decoration: underline;
				}
				&:active, &:focus, &:not(:disabled):not(.disabled):focus {
					color: $brandcolor1-dark;
					text-decoration: underline;
				}
			}
		}
	}
}