/* Main Projects Page */
.project-cards-outer-container {
	margin: 0;
	padding: 2.5rem 3.125rem 5.0rem 3.125rem;
	.project-card-container {
		padding: 0 0.625rem;
		display: flex;
		justify-content: center;
		align-items: flex-start;
	}
}

// Project Teaser Cards
.project-card {
	// width: calc(100% - 1.25em);
	margin: 0 0 1.25rem 0;
	&:hover {
		.img-zoom-hover {
			img {
				transform: scale(1.125);
			}
		}
		.card-body {
			.project-teaser-headline {
				.card-title {
					color: $brandcolor1-light;
					text-decoration: none;
				}
			}
			.project-teaser-copy {
				opacity: 1;
			}
			.card-footer {
				a {
					color: $brandcolor1-light;
					text-decoration: none;
				}
				&::after {
					color: $brandcolor1-light;
				}
			}
		}
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		.card-body {
			.project-teaser-headline {
				.card-title {
					color: $brandcolor1;
					text-decoration: none;
				}
			}
			.project-teaser-copy {
				opacity: 1;
			}
			.card-footer {
				a {
					color: $brandcolor1;
					text-decoration: none;
				}
				&::after {
					color: $brandcolor1;
				}
			}
		}
	}
	.img-zoom-hover {
		@extend .border-radius-rounded;
		.card-img-top {
			@extend .border-radius-rounded;
		}		
	}
	.card-img-overlay {
		background: linear-gradient(0deg, rgba($black,0.60) 0%, rgba($black,0.2) 30%, rgba($black,0) 100%);
		@extend .border-radius-rounded;
	}
	.card-body {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: flex-start;
		@extend .border-radius-rounded;
		.project-teaser-date {
			.card-text {
				color: $white;
			}
		}
		.project-teaser-headline {
			.card-title {
				margin: 0 1.5rem 0.3125rem 0;
				color: $white;
				font-weight: 200;
				-webkit-transition: color 1.0s ease-out;
				-moz-transition: color 1.0s ease-out;
				-ms-transition: color 1.0s ease-out;
				-o-transition: color 1.0s ease-out;
				transition: color 1.0s ease-out;
			}
		}
		.project-teaser-copy {
			color: $gray-white0;
			font-size: 0.875rem;
			line-height: 1.25;
			opacity: 0;
			height: 3.75rem;
			overflow: hidden;
			-webkit-mask-image: linear-gradient(180deg, #000 75%, transparent);
			-webkit-transition: opacity 1.0s ease-out;
			-moz-transition: opacity 1.0s ease-out;
			-ms-transition: opacity 1.0s ease-out;
			-o-transition: opacity 1.0s ease-out;
			transition: opacity 1.0s ease-out;
		}
		.card-footer {
			padding: 0.3125em 0;
			a {
				display: block;
				color: $white;
			}
			&::after {
				content: "\f014";
				font-family: $font-icons2;
				display: block;
				font-size: 1.5em;
				font-weight: normal;
				font-style: normal;
				font-variant: normal;
				text-rendering: auto;
				-webkit-font-smoothing: antialiased;
				text-rendering: auto;
				position: absolute;
				z-index: 2;
				bottom: 0.625rem;
				right: 0.625rem;
				color: $white;
			}
		}
	}
}

/* Project Detail Page */
.project-detail-container {
	.project-overview-container {
		margin: 0;
		padding: 5.0rem 2.5rem 5.0rem 2.5rem;
		.project-overview-body-container {
			.project-overview-body-headline-container {
				margin-bottom: 2.5rem;
			}
		}
		.project-overview-lists-container {
			column-count: 2;
			column-gap: 2.5em;
			.project-overview-single-list-container {
				break-inside: avoid-column;
				// margin-bottom: 0.625rem;
				.project-overview-list-header {
					font-size: 0.9375rem;
					font-weight: 600;
					line-height: 1;
					letter-spacing: 0.05rem;
					color: $brandcolor1;
					text-transform: uppercase;
				}
			}
			@media (max-width: 991.98px) {
				column-count: 1;
			}
			@media (max-width: 575.98px) {
				column-count: 2;
				padding-top: 1.25rem;
			}
			@media (max-width: 459.98px) {
				column-count: 1;
			}
		}
	}

	.header-image-slider-full-width {
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: $black;
		.carousel-overlay {
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			width: 100%;
			height: 100%;
			padding: 0;
			margin: 0;
			background: linear-gradient(180deg, rgba($black,0.60) 0%, rgba($black,0) 30%, rgba($black,0) 100%);
			z-index: 1;
			pointer-events: none;
		}
		.image-slider {
			&.loading {
				//min-height: 60vh;
			}
			.image-slider-wrapper {
				.image-slide {
					.image-slide-image {
						//max-height: 60vh;
					}
				}
			}
		}
	}
}

//Project Callouts Block
.block-container-projects-callout {
	background: linear-gradient(180deg, rgba($gray-dark2,0) 0%, rgba($gray-dark2,1.0) 75%, rgba($gray-dark2,0) 100%);
	.block-projects-callout-header-row {
		margin: 0;
		padding: 0 0 1.25rem 0;
		.block-projects-callout-header-container {
			margin: 0;
			padding: 0;
			.block-latest-news-header {
				margin: 0;
				padding: 0;
				color: $white;
				line-height: 1;
				@media (max-width: 575.9px) {
					padding: 0 0 0.625rem 0;
				}
			}
		}
		.block-projects-callout-button-container {
			margin: 0;
			padding: 0;
			.btn-block-projects-more {
			}
		}
	}
	.block-projects-callout-cards-row {
		.swiper {
			overflow: visible;
			.swiper-wrapper {
				.swiper-slide {
					.project-card {
						margin: 0 !important;
						opacity: 0.5;
						@extend .border-radius-rounded;
						-webkit-filter: blur(4px) grayscale(0%) contrast(50%);
						-moz-filter: blur(4px) grayscale(0%) contrast(50%);
						-ms-filter: blur(4px) grayscale(0%) contrast(50%);
						-o-bfilter: blur(4px) grayscale(0%) contrast(50%);
						filter: blur(4px) grayscale(0%) contrast(50%);
						-webkit-transition: filter 2.0s ease-out, opacity 2.0s ease-out, box-shadow 2.0s ease-out;
						-moz-transition: filter 2.0s ease-out, opacity 2.0s ease-out, box-shadow 2.0s ease-out;
						-ms-transition: filter 2.0s ease-out, opacity 2.0s ease-out, box-shadow 2.0s ease-out;
						-o-transition: filter 2.0s ease-out, opacity 2.0s ease-out, box-shadow 2.0s ease-out;
						transition: filter 2.0s ease-out, opacity 2.0s ease-out, box-shadow 2.0s ease-out;
						box-shadow: 0px 100px 80px rgba(0, 0, 0, 0);
					}
				}
				.swiper-slide-active {
					.project-card {
						opacity: 1.0;
						-webkit-filter: blur(0) grayscale(0) contrast(100%);
						-moz-filter: blur(0) grayscale(0) contrast(100%);
						-ms-filter: blur(0) grayscale(0) contrast(100%);
						-o-bfilter: blur(0) grayscale(0) contrast(100%);
						filter: blur(0) grayscale(0) contrast(100%);
						box-shadow:
							0px 1.6px 1.2px rgba(0, 0, 0, 0.042),
							0px 3.4px 2.7px rgba(0, 0, 0, 0.062),
							0px 5.8px 4.6px rgba(0, 0, 0, 0.077),
							0px 8.7px 6.9px rgba(0, 0, 0, 0.089),
							0px 12.5px 10px rgba(0, 0, 0, 0.1),
							0px 17.7px 14.2px rgba(0, 0, 0, 0.111),
							0px 25.1px 20.1px rgba(0, 0, 0, 0.123),
							0px 36.5px 29.2px rgba(0, 0, 0, 0.138),
							0px 56.3px 45px rgba(0, 0, 0, 0.158),
							0px 100px 80px rgba(0, 0, 0, 0.2) !important
						;
					}
				}
				@media (min-width: 992px) {
					.swiper-slide-prev {
						.project-card {
							opacity: 1.0;
							-webkit-filter: blur(0) grayscale(0) contrast(100%);
							-moz-filter: blur(0) grayscale(0) contrast(100%);
							-ms-filter: blur(0) grayscale(0) contrast(100%);
							-o-bfilter: blur(0) grayscale(0) contrast(100%);
							filter: blur(0) grayscale(0) contrast(100%);
							box-shadow:
								0px 1.6px 1.2px rgba(0, 0, 0, 0.042),
								0px 3.4px 2.7px rgba(0, 0, 0, 0.062),
								0px 5.8px 4.6px rgba(0, 0, 0, 0.077),
								0px 8.7px 6.9px rgba(0, 0, 0, 0.089),
								0px 12.5px 10px rgba(0, 0, 0, 0.1),
								0px 17.7px 14.2px rgba(0, 0, 0, 0.111),
								0px 25.1px 20.1px rgba(0, 0, 0, 0.123),
								0px 36.5px 29.2px rgba(0, 0, 0, 0.138),
								0px 56.3px 45px rgba(0, 0, 0, 0.158),
								0px 100px 80px rgba(0, 0, 0, 0.2) !important
							;
						}
					}
				}
				@media (min-width: 576px) {
					.swiper-slide-next {
						.project-card {
							opacity: 1.0;
							-webkit-filter: blur(0) grayscale(0) contrast(100%);
							-moz-filter: blur(0) grayscale(0) contrast(100%);
							-ms-filter: blur(0) grayscale(0) contrast(100%);
							-o-bfilter: blur(0) grayscale(0) contrast(100%);
							filter: blur(0) grayscale(0) contrast(100%);
							box-shadow:
								0px 1.6px 1.2px rgba(0, 0, 0, 0.042),
								0px 3.4px 2.7px rgba(0, 0, 0, 0.062),
								0px 5.8px 4.6px rgba(0, 0, 0, 0.077),
								0px 8.7px 6.9px rgba(0, 0, 0, 0.089),
								0px 12.5px 10px rgba(0, 0, 0, 0.1),
								0px 17.7px 14.2px rgba(0, 0, 0, 0.111),
								0px 25.1px 20.1px rgba(0, 0, 0, 0.123),
								0px 36.5px 29.2px rgba(0, 0, 0, 0.138),
								0px 56.3px 45px rgba(0, 0, 0, 0.158),
								0px 100px 80px rgba(0, 0, 0, 0.2) !important
							;
						}
					}
				}
			}
			.swiper-button-prev {
				left: -4.0rem;
				@media (max-width: 1200px) {
					left: -3.0rem;
				}
				@media (max-width: 992px) {
					left: -2.0rem;
				}
				@media (max-width: 576px) {
					left: -1.0rem;
				}
			}
			.swiper-button-next {
				right: -4.0rem;
				@media (max-width: 1200px) {
					right: -3.0rem;
				}
				@media (max-width: 992px) {
					right: -2.0rem;
				}
				@media (max-width: 576px) {
					right: -1.0rem;
				}
			}
		}
	}
}